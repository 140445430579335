defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/FAQFeedback', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader'
], function (ApiRequest, ComponentLoader) {

  ComponentLoader('faq-feedback', function (settings) {
    var $element = this.$element;

    this.articleId = settings.articleId || '';
    this.submitEndpoint = settings.submitEndpoint || '/dli/scapi/danskespil/faq/like';
    this.hasLikedClass = settings.hasLikedClass || 'has-liked';
    this.hasDislikedClass = settings.hasDislikedClass || 'has-disliked';
    this.likeButton = $element.querySelector(settings.likeSelector || '.js-feedback-like'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.dislikeButton = $element.querySelector(settings.likeSelector || '.js-feedback-dislike'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

    this.submitFeedback = function (article, like) {
      ApiRequest({
        url: this.submitEndpoint,
        method: 'POST',
        data: {
          article: article,
          like: like
        }
      }).then(function () {
        if (like) {
          $element.classList.add(this.hasLikedClass);
        } else {
          $element.classList.add(this.hasDislikedClass);
        }
      }.bind(this));
    }.bind(this);

    this.likeButton.addEventListener('click', function () {
      this.submitFeedback(this.articleId, true);
    }.bind(this));

    this.dislikeButton.addEventListener('click', function () {
      this.submitFeedback(this.articleId, false);
    }.bind(this));
  });
});
