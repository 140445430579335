defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader',
  [],
  function () {

    // Variables:
    var count = 0;

    // Functions:
    var detect = function (name) {
      return Array.prototype.filter.call(document.querySelectorAll('[data-component="' + name + '"]'), function (item) {
        return !item.hasAttribute('data-component-count');
      });
    };

    var initialize = function (name, callback) {
      var $elements = detect(name);

      // If there are no elements to mount, we return early:
      if ($elements.length === 0) {
        return;
      }

      // DOM:
      $elements.forEach(function ($element) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        register($element);

        var internals = {
          $element: $element,
          count: count
        };

        var settings = $element.getAttribute('data-settings') || {}; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        if (typeof settings === 'string') {
          settings = JSON.parse(settings);
        }

        callback.bind({ $element: internals.$element })(settings, internals.$element);

        count++;
      });
    };

    var register = function ($element) {
      $element.setAttribute('data-component-count', count); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    };

    // Public functions:
    return initialize;

  });
