defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/KvnInformation', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader',
  'Common/Framework/EventHandling/Scripts/OutsideClick',
], function (ApiRequest, ComponentLoader, OutsideClick) {

  ComponentLoader('kvn-information', function () {
    var operationsMessengerApi = DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/messages?platform=Sitecore' || null;
    var kvnInformation = this.$element;  // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
    var kvnInformationList = kvnInformation.querySelector('.js-kvn-information-list');
    var kvnInformationCta = kvnInformation.querySelector('.js-kvn-information-cta');
    var kvnInformationClose = kvnInformation.querySelector('.js-kvn-information-cta-close');

    var addListItem = function (message) {
      var li = document.createElement('li');
      li.classList.add('kvn-information__list-item');
      li.innerText = message.heading;
      return li;
    };

    var fetchKvnErrors = function () {
      ApiRequest({
        url: operationsMessengerApi,
        localCacheTTL: 25,
      }).then(function (res) {
        if (res && res.messages && res.messages.length > 0) {
          kvnInformationList.querySelectorAll('li').forEach(function (listItem) {
            if (!listItem.classList.contains('js-kvn-information-list-item-fallback')) {
              listItem.parentElement.removeChild(listItem);
            }
          });

          res.messages.forEach(function (message) {
            kvnInformationList.appendChild(addListItem(message));
          });

          kvnInformation.classList.add('kvn-information--has-errors');
        } else {
          kvnInformation.classList.remove('kvn-information--has-errors');
        }
      });
    };

    var hideList = function () {
      kvnInformation.classList.remove('kvn-information--open');
    };

    var toggleList = function () {
      kvnInformation.classList.toggle('kvn-information--open');
    };

    kvnInformationCta.addEventListener('click', toggleList);
    kvnInformationClose.addEventListener('click', toggleList);

    OutsideClick(kvnInformation, window, hideList);
    fetchKvnErrors();
    setInterval(fetchKvnErrors, 30000); // Fetch any possible error messages every 30 sec.

  });
});
