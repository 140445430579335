defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ChatButton', [
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Data/UserInformation',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache'
], function (ComponentLoader, Utils, UserInformation, LoginCache) {

  ComponentLoader('chat-button', function (settings) {
    this.debounce = Utils.debounce;
    this.attentionTimer = parseInt((settings.attentionTimer * 1000) || null, 10);
    this.attentionClass = settings.attentionClass || 'do-animation';

    this.doAnimation = function () {
      this.$element.classList.add('do-animation');
      this.$element.classList.add('has-shown-attention');

      this.$element.addEventListener('animationend', this.removeAnimationClass); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      this.$element.addEventListener('webkitAnimationEnd', this.removeAnimationClass); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      this.$element.addEventListener('mozAnimationEnd', this.removeAnimationClass); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }.bind(this);

    this.removeAnimationClass = function () {
      this.$element.classList.remove('do-animation');
    }.bind(this);

    this.fetchUserInfo = new Promise(function (resolve) {
      var user = LoginCache.getUserObj();
      var userInfo = sessionStorage.getItem('kundecenter-info');

      // If available from sessionStorage resolve() immidiately without doing an API call
      if (userInfo) {
        resolve(JSON.parse(userInfo));
      }

      // If visitor has a userObject, they are logged in to DLI. We can fetch data to get the email and phone number as well
      if (user && user.customerInfo) {
        UserInformation.getDliUser().then(function (response) {
          resolve(this.populateUserFields(response));
        }.bind(this), function () {
          resolve(null);
        });
      // Else try to fetch it from DLO
      } else {
        UserInformation.getDloUser().then(function (response) {
          resolve(this.populateUserFields(response));
        }.bind(this), function () {
          resolve(null);
        });
      }
    }.bind(this));

    this.populateUserFields = function (userInfo) {
      if (userInfo) {
        sessionStorage.setItem('kundecenter-info', JSON.stringify(userInfo));
      }

      return userInfo;
    };


    this.initESW = function (gslbBaseURL, userInfo) {
      // Prepopulate the chat fields if it's available from sessionStorage
      // This is custom implementation to the Salesforce Embedded Chat

      // Disable lint for non camelcase properties. This is props defined by Salesforce
      /* eslint-disable camelcase, no-undef */
      if (userInfo) {
        embedded_svc.settings.prepopulatedPrechatFields = userInfo;
      }

      if (settings.avatarImgURL) {
        embedded_svc.settings.avatarImgURL = settings.avatarImgURL;
      }

      embedded_svc.settings.displayHelpButton = true;
      embedded_svc.settings.language = settings.language;
      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';

      embedded_svc.init(
        settings.baseCoreURL,
        settings.communityEndpointURL,
        gslbBaseURL,
        settings.orgId,
        settings.releaseVersion,
        {
          baseLiveAgentContentURL: settings.baseLiveAgentContentURL,
          deploymentId: settings.deploymentId,
          buttonId: settings.buttonId,
          baseLiveAgentURL: settings.baseLiveAgentURL,
          eswLiveAgentDevName: settings.eswLiveAgentDevName,
          isOfflineSupportEnabled: false
        }
      );
      /* eslint-enable camelcase, no-undef */

      this.initAttentionTimer();
    };

    this.initAttentionTimer = function () {
      if (this.attentionTimer > 0) {
        var initialLoadTimer = setTimeout(function () {
          this.doAnimation();
        }.bind(this), this.attentionTimer);

        window.onmousemove = function () {
          clearTimeout(initialLoadTimer);
        };

        document.addEventListener('mousemove', this.debounce(function () {
          this.doAnimation();
        }.bind(this), this.attentionTimer), false);
      }
    }.bind(this);

    // Try to fetch user info and the init
    this.fetchUserInfo.then(function (userInfo) {
      // Load the Salesforce embedded chat
      if (!window.embedded_svc) { // eslint-disable-line no-undef -- Reason: Appended by Salesforce
        var s = document.createElement('script');
        s.setAttribute('src', 'https://kundecrm--Full.cs108.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        s.onload = function () {
          this.initESW(null);
        };
        document.body.appendChild(s);
      } else {
        this.initESW('https://service.force.com', userInfo);
      }
    }.bind(this));
  });
});
