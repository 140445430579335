defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/FAQToggle', [
  'Common/Framework/EventHandling/Scripts/OutsideClick',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader'
], function (OutsideClick, ComponentLoader) {

  ComponentLoader('faq-toggle', function (settings) {
    var $element = this.$element;
    var avalonSidebar = document.querySelector('.avalon-sidebar');

    this.selectors = $element.querySelectorAll(settings.selector) || $element; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.target = $element.querySelector(settings.target) || $element; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.toggleClass = $element.querySelector(settings.toggleClass) || 'is-open'; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.closeOutsideClick = settings.closeOutsideClick || true;

    // If the toggle is the brand menu and if it's located in the sidebar then remove some generic functionality
    if (avalonSidebar !== null && avalonSidebar !== $element && avalonSidebar.contains(document.querySelector('.faq-brand-menu__container'))) {
      this.target.classList.add(this.toggleClass);
      this.closeOutsideClick = false;
    }

    // Map to a new array, because IE11 does not support forEach on NodeList
    this.selectors = [].slice.call(this.selectors);

    this.toggleClick = function () {
      this.target.classList.toggle(this.toggleClass);
    }.bind(this);

    this.hideTarget = function () {
      this.target.classList.remove(this.toggleClass);
    }.bind(this);

    // Add event listeners to all selectors within the component
    this.selectors.forEach(function (selector) {
      selector.addEventListener('click', this.toggleClick, false);
    }.bind(this));

    if (this.closeOutsideClick) {
      // Hide box when clicking outside the toggle button
      OutsideClick($element, window, this.hideTarget);
    }
  });
});
