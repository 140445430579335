defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/FAQAnnouncements', [
  'DanskeSpil/Domain/AvalonComponents/Scripts/Slider',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader'
], function (Slider, ComponentLoader) {

  ComponentLoader('faq-announcements', function () {

    var $element = this.$element;

    /**
     * Handle when and when not to initialize cards as a slider
     */
    this.initializeSliderLogic = function () {
      var containers = document.querySelectorAll('.js-announcement-items__container');

      // Map to a new array, because IE11 does not support forEach on NodeList
      containers = [].slice.call(containers);

      containers.forEach(function (elem) {

        // Get the parent mega-mode element, because the HTML markup required for the Swiper library to work starts with the mega-mode parent
        var $parent = elem.closest('.js-swiper-container');
        var numberOfCards = elem.querySelectorAll('.js-announcement__item').length;

        var sliderConfig = {
          swiper: null,
          shouldInitialize: function () {
            var isMobile = document.body.classList.contains('is-mobile-detected');
            var isTablet = document.body.classList.contains('is-tablet-detected');

            if ((numberOfCards > 1 && isMobile) || (numberOfCards > 2 && isTablet) || (numberOfCards > 3)) {
              $element.classList.add('has-swiper');
              return true;
            }

            return false;
          },
          target: $parent,
          // swiper config object
          settings: {
            slidesPerView: 'auto',
            scrollbar: {
              el: '.swiper-scrollbar',
              draggable: true,
              hide: false,
              dragSize: '30',
            }
          }
        };

        // Add swiper configuration to the global swiper handler
        Slider.addSlider(sliderConfig);

      });
    };

    this.init = function () {
      if (document.body.classList.contains('mode-edit').length) return;
      this.initializeSliderLogic();
    }.bind(this);

    this.init();

  });
});

