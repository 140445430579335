defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Templates/Icon',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    var Icon = function (icon, cssClass) {
      cssClass = cssClass || '';

      return m('svg', { class: cssClass + ' avalon-icon avalon-icon--sm' }, [
        m('use', { href: '/Components/DanskeSpil/Domain/AvalonKundecenter/Graphics/SpriteSheets/Icons.svg#' + icon })
      ]);
    };

    return Icon;

  });