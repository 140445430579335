defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Data/UserInformation', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
],
function (ApiRequest) {

  // Functions:
  var getDliUser = function () {
    return ApiRequest({
      method: 'GET',
      url: '/dli/scapi/danskespil/playeraccountmanagement/PlayerProfile',
      requireAuthCookie: true,
      logging: false,
      background: false,
      unwrapSuccess: function (response) {
        if (response && response.data && response.data.controls) {
          var userObj = response.data.controls;

          var user = {
            FirstName: userObj.FirstName.Value,
            LastName: userObj.LastName.Value,
            Email: userObj.Email.Value,
            Phone: userObj.PhoneNumber.Value
          };

          return user;
        }

        return null;
      }
    });
  };

  var getDloUser = function () {
    return ApiRequest({
      method: 'GET',
      url: '/dlo/scapi/danskespil/playeraccountmanagement/PlayerProfile',
      requireAuthCookie: true,
      logging: false,
      background: false,
      unwrapSuccess: function (response) {
        if (response && response.data && response.data.controls) {
          var userObj = response.data.controls;

          var user = {
            FirstName: userObj.FirstName.Value,
            LastName: userObj.LastName.Value,
            Email: userObj.Email.Value,
            Phone: userObj.PhoneNumber.Value
          };

          return user;
        }

        return null;
      }
    });
  };

  var updateEmail = function (email, context) {
    if (!context) {
      context = DS.Config.CONTEXTPREFIX;
    }

    return ApiRequest({
      method: 'POST',
      url: context + '/scapi/danskespil/playeraccountmanagement/UpdateEmail',
      background: false,
      data: {
        value: email
      },
      unwrapSuccess: function (response) {
        if (response && response.data && response.data.BodyText) {
          return {
            status: 'success',
            message: response.data.BodyText
          };
        }

        return null;
      },
      unwrapError: function (response) {
        if (response && response.errors) {
          return {
            status: 'error',
            message: response.errors[0].Message
          };
        }
      }
    });
  };

  // Public functions:
  return {
    getDliUser: getDliUser,
    getDloUser: getDloUser,
    updateEmail: updateEmail
  };
});
