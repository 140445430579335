defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Components/Recaptcha', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/Recaptcha'
], function (m, RecaptchaHelper) {
  'use strict';

  var Recaptcha = {

    controller: function (options) {
      var self = this;
      self.options = {
        model: null,
        validate: null,
        align: 'left', // left, right or center
        class: null,
        id: '',
        uiTestId: null,
        ...options
      };

      self.options.model(self.options.model() ? self.options.model().toString() : self.options.model()); // in case model is not already a string, this conversion helps for regex validation
      self.model = self.options.model;

      window.reCaptchaReady = RecaptchaHelper.reCaptchaReady;

      window.reCaptchaCallback = function () {
        self.model.validate(['recaptcha']).then(m.redraw, m.redraw);
      };
    },

    view: function (ctrl) {
      var options = ctrl.options;
      var haserror = ctrl.model.errorList && ctrl.model.errorList().length > 0;
      return m('.recaptcha-container', [
        m('div', {
          id: options.id,
          class: options.class ? options.class : ['faq-form__recaptcha', 'faq-form__recaptcha--align-' + options.align].join(' '),
          config: function (elem, inited) {
            if (!inited) {
              RecaptchaHelper.reCaptchaInit(elem);
            }
          }
        }, []
        ), [haserror ?
          ctrl.model.errorList().map(function (msg) {
            return m('div', { class: 'has-error faq-form__recaptcha-error' }, m.trust(msg));
          }) : null
        ]]);
    }
  };
  return Recaptcha;
});
