defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Data/CustomValidators', [
  'Common/Framework/ScriptLibraries/Scripts/validate'
], function (validate) {
  'use strict';

  return {
    validate: validate,
    add: function () {

      validate.validators.allowedFileSize = function (value, options) {
        var file = value;

        if (!file) return;

        if (!window.File && !window.FileReader && !window.FileList && !window.Blob) {
          // If browser does not support File, FileReader, FileList og Blob, then don't validate this
          return;
        }

        if (options.maxSize < file.size) {
          return options.message;
        }
      };

      validate.validators.allowedMimeTypes = function (value, options) {
        var file = value;

        if (!file) return;

        if (!window.File && !window.FileReader && !window.FileList && !window.Blob) {
          // If browser does not support File, FileReader, FileList og Blob, then don't validate this
          return;
        }

        if (options.mimeTypes.indexOf(file.type) === -1) {
          return options.message;
        }
      };

    }

  };

});
