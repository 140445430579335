defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Templates/Receipt',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/AvalonKundecenter/Scripts/Templates/Icon'
  ],
  function (m, Icon) {

    var Receipt = function (content) {
      return m('.faq-form__receipt-page', [
        Icon(content.icon, 'faq-form__receipt-icon'),
        m('.faq-form__receipt-header', [
          m('h1.faq-form__title', content.title),
          m('h2.faq-form__description', content.description)
        ]),
        m('.faq-form__group', [
          m('a.faq-form__button', {
            href: content.button.href
          }, content.button.text),
        ])
      ]);
    };

    return Receipt;

  });