defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Components/InputField', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Templates/Icon'
], function (m, Icon) {
  'use strict';

  var InputField = {

    controller: function (options) {

      var self = this;

      /*
        The model must be an m.prop() with some added, optional properties: 1) .validState - a
        method returning whether the input is valid, and 2) errorList - a list of strings (m.prop)
        describing the validation errors, if any.
      */
      self.options = {
        model: null,
        pattern: /.*/,
        onchange: null,
        validate: null,
        onblur: function () { },
        onkeyup: function () { },
        onkeydown: function () { },
        type: 'text', // any type allowed on HTML <input/>
        placeholder: null,
        disabled: m.prop(false),
        class: '',
        id: '',
        name: '',
        spellcheck: false,
        ...options
      };

      self.options.model(self.options.model() ? self.options.model().toString() : self.options.model()); // in case model is not already a string, this conversion helps for regex validation

      self.model = self.options.model;

      self.hasfocus = m.prop(false);

      self.updateFocus = function (hasFocus) {
        self.hasfocus(hasFocus);
      };

      self.oninput = function (e) {
        var v = e.target['value'];

        self.model(v);
        if (self.model.validState !== undefined) {
          self.model.validState(true);
          self.model.errorList([]);
        }
      };
    },

    view: function (controller) {
      var options = controller.options;
      var hasError = controller.model.errorList && controller.model.errorList().length > 0;

      return m('div', {
        class: 'faq-form__column faq-form__input-group' + (options.isRequired ? ' is-required' : '') + ((!controller.hasfocus() || controller.model.forcefullyValidated) && hasError && controller.model.validState && !controller.model.validState() ? ' has-error' : ''),
      }, [
        m('label.faq-form__label', options.label),
        m('div.faq-form__input-wrap', [
          (options.type === 'textarea' ?
            m('textarea.faq-form__textarea', {
              onkeypress: function (e) {
                e.stopPropagation();
              },
              placeholder: options.placeholder,
              value: controller.model() ? controller.model() : '',
              oninput: controller.oninput,
              onfocus: function () {
                controller.updateFocus(true);
                if (options.onfocus) {
                  options.onfocus();
                }
              },
              onblur: function (e) {
                controller.updateFocus(false);
                if (e.target.value.length) {
                  options.onblur(e);
                }
              }
            })
            :
            m('input.faq-form__input', {
              type: options.type,
              placeholder: options.placeholder,
              value: controller.model() ? controller.model() : '',
              oninput: controller.oninput,
              onfocus: function () {
                controller.updateFocus(true);
                if (options.onfocus) {
                  options.onfocus();
                }
              },
              onblur: function (e) {
                controller.updateFocus(false);
                if (e.target.value.length) {
                  options.onblur(e);
                }
              }
            })
          ),
          options.isRequired ? Icon('kc-error', 'faq-form__state-icon') : null
        ]),
        (!controller.hasfocus() || controller.model.forcefullyValidated) && hasError ?
          controller.model.errorList().map(function (msg) {
            return m('.faq-form__error-message', m.trust(msg));
          }) : null,
      ]);

    }

  };

  return InputField;
});
