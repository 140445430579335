defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Models/ValidationModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/ScriptLibraries/Scripts/validate',
], function (m, validate) {
  'use strict';

  function ValidationModel(model) {

    validate.Promise = Promise;

    /*
      This method runs the validate method of validate.js on a number of given fields of the
      model, which must 1) have a specific structure (see commments below), and 2) have been
      extended by calling extendFields (see below). A subset of its fields may be specified -
      e.g. for validating just the fields of a step in a 'wizard', or for validating two fields
      whose validity depend on each other. When calling extendField the method will be hooked
      up to each field of the model (by a new property 'validate', see below) and may thus
      also be used inside an input control component that has one field as its model.
    */

    model.prototype.validate = function fieldsValidate(fieldKeys, forceValidate) {
      var fields = {};
      var constraints = {};
      var self = this;
      fieldKeys = fieldKeys || [];
      forceValidate = forceValidate || false;

      if (typeof (fieldKeys) === 'string') {
        fieldKeys = [fieldKeys];
      }

      // Set up parameters for the call to validate.js
      for (var i = 0; i < fieldKeys.length; i++) {
        var field = this.fields[fieldKeys[i]];
        var fieldRules = this.fieldrules[fieldKeys[i]]; // field rules must be specified in validate.js' format

        if (fieldRules) {
          constraints[fieldKeys[i]] = fieldRules;
        }

        /*
          This method supports a model where a field can be either an m.prop() or an object
          with an m.prop() property named 'value'.
        */
        if (typeof (field) === 'function') { // This is a Mithril m.prop
          fields[fieldKeys[i]] = field();
        } else if (field && typeof (field.value) === 'function') {

          fields[fieldKeys[i]] = field.value();
        } else {
          console.error('Could not find ' + fieldKeys[i] + ' in model fields.');
        }
      }

      // RSVP is defined globally
      var chain = Promise.resolve(1); // to ensure that validations will run consecutively

      chain = chain.then(function () {

        return validate.async(fields, constraints, { fullMessages: false });
      }).then(function () {

        Object.keys(fields).forEach(function (fieldName) {
          self.fields[fieldName].validState(true);
          self.fields[fieldName].errorList([]);
          self.fields[fieldName].forcefullyValidated = forceValidate;
        });
      }, function (errors) {

        Object.keys(fields).forEach(function (fieldName) {
          /* Read the fieldsrules for the specific fieldname */

          var fieldValidatorRules = self.fieldrules[fieldName];
          fieldValidatorRules = typeof(fieldValidatorRules) == 'function' ? fieldValidatorRules() : fieldValidatorRules;

          var relatedFieldKeys = null;

          if (fieldValidatorRules) {
            Object.keys(fieldValidatorRules).forEach(
              function (fieldValidator) {
                if (fieldValidatorRules[fieldValidator] && fieldValidatorRules[fieldValidator].hasOwnProperty('relatedFieldKeys')) {
                  relatedFieldKeys = fieldValidatorRules[fieldValidator].relatedFieldKeys;
                }
              }
            );
          }

          /* if these realtedkeys is not null, use these, otherwise use array of 1 with fieldname */
          var fieldsToCheck = relatedFieldKeys ? relatedFieldKeys : [fieldName];

          fieldsToCheck.forEach(function (fieldToCheck) {
            if (fieldName in errors) {

              var fieldErrors = [];

              errors[fieldName].forEach(function (err) {
                if (typeof err === 'string') {
                  fieldErrors.push(err);
                } else if (err.hasOwnProperty('Detail') && err.Source.Pointer === fieldToCheck) {
                  fieldErrors.push(err.Detail);
                }
              });

              if (fieldErrors.length > 0) {
                self.fields[fieldToCheck].validState(false);
                self.fields[fieldToCheck].errorList(fieldErrors);
              }


            } else {
              self.fields[fieldToCheck].validState(true);
              self.fields[fieldToCheck].errorList([]);
            }

            self.fields[fieldToCheck].forcefullyValidated = forceValidate;

          });

        });
      }).then(function () {
        m.redraw(true);
      }, function () {
        m.redraw(true);
      });

      return chain;
    };

    model.prototype.clearErrors = function () {
      var fields = this.fields;
      Object.keys(fields).map(function (value) {
        var f;
        if (typeof fields[value] === 'function') {
          f = fields[value];
        } else if (typeof fields[value].value === 'function') {
          f = fields[value].value;
        } else {
          f = fields[value];
        }
        f.validState(true);
        f.errorList([]);
      });
    };

    model.prototype.toPlainObject = function toPlainObject() {
      var fields = this.fields;
      var plain = {};

      Object.keys(fields).map(function (value) {
        if (typeof fields[value] === 'function') {
          plain[value] = fields[value]();
        } else if (typeof fields[value].value === 'function') {
          plain[value] = fields[value].value();
        } else {
          plain[value] = fields[value];
        }
      });

      return plain;
    };

    model.prototype.getAllErrors = function toPlainObject() {
      var self = this;
      var errors = [];
      Object.keys(self.fields).forEach(function (fieldKey) {
        var errorList = self.fields[fieldKey].errorList;
        if (errorList) {
          errorList.forEach(function (error) {
            errors.push(error);
          });
        }
      });

      return errors;
    };

    model.prototype.isModelValid = function isModelValid() {
      var self = this;
      var modelState = true;

      Object.keys(self.fields).some(function (fieldKey) {
        var fieldState = self.fields[fieldKey].validState();
        if (!fieldState) {
          modelState = false;
        }
        return fieldState === false;
      });

      return modelState;
    };

    /*
      After validating some or all of the fields of the model (using the validate method above) you
      might like to know whether a certain subset of fields - e.g. some fields used in a step of a
      'wizard' - are all valid. This method reports the states - and the 'combined' state - of such a
      subset of fields.
    */
    model.prototype.getStepState = function getStepState(fieldKeys) {
      var groupState = true;
      var fieldStates = {};

      if (typeof (fieldKeys) === 'string') {
        fieldKeys = [fieldKeys];
      }

      for (var i = 0; i < fieldKeys.length; i++) {
        var field = this.fields[fieldKeys[i]];

        // If it's a collection
        if (typeof field === 'function' && field() && field().hasOwnProperty('models')) {
          fieldStates[fieldKeys[i]] = [];
          field().models.forEach(function (model) {
            var modelState = model.isModelValid();
            fieldStates[fieldKeys[i]].push(modelState);
            if (!modelState) {
              groupState = false;
            }
          });
        } else {
          var state = this.fields[fieldKeys[i]].validState();
          fieldStates[fieldKeys[i]] = state;
          if (!state) {
            groupState = false;
          }
        }
      }

      return {
        state: groupState,
        fields: fieldStates
      };
    };

    model.prototype.extendFields = function extendFields(model) {
      for (var field in model.fields) {
        var f = model.fields[field];
        f.validState = m.prop(true);
        f.fieldName = field;
        f.validate = function () {
          return model.validate(this);
        }.bind(field);
        f.errorList = m.prop([]);
      }

      return model;
    };

    return model;
  }

  return ValidationModel;
});
