defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/FormApi',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
  ],
  function (ApiRequest) {

    // Variables:
    var baseURL = window.DS.Config.CONTEXTPREFIX + '/scapi/danskespil/faq/webform';

    var submitForm = function (data) {
      return ApiRequest({
        method: 'POST',
        url: baseURL + '/sendcasewithattachment',
        data: data,
        serialize: function (data) {
          return data;
        },
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };

    // Public functions:
    return {
      submitForm: submitForm
    };

  });
