defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Models/FormModel', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Core/Model',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Models/ValidationModel',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Data/CustomValidators'
], function (m, Model, ValidationModel, customValidators) {
  'use strict';

  var FormModel = Model('FormModel', new ValidationModel(function (content) {

    customValidators.add();

    var d = content.dictionary;

    this.fields = {
      name: m.prop(content.name || null),
      email: m.prop(content.email || null),
      phone: m.prop(content.phone || null),
      brand: m.prop(content.brand || null),
      subject: m.prop(content.subject || null),
      message: m.prop(content.message || null),
      files: m.prop([]),
      recaptcha: m.prop(null)
    };

    this.fieldrules = {
      name: {
        presence: {
          message: d('Webform/Validation/NameRequired')
        }
      },
      email: {
        presence: {
          message: d('Webform/Validation/EmailRequired')
        },
        email: {
          message: d('Webform/Validation/EmailNotCorrectFormat')
        }
      },
      message: {
        presence: {
          message: d('Webform/Validation/MessageRequired')
        }
      },
      files: {
        file: {
          allowedMimeTypes: {
            mimeTypes: content.allowedMimeTypes,
            message: d('Webform/Validation/NotCorrectMimeType')
          },
          allowedFileSize: {
            maxSize: content.allowedFileSize || (25 * 1024 * 1024), // 1 megabyte * 1024 * 1024
            message: d('Webform/Validation/FileSizeExceeeded', { MAXSIZE: content.allowedFileSize || (25 * 1024 * 1024) })
          }
        }
      },
      recaptcha: function () {
        if (window.grecaptcha) {
          this.fields.recaptcha(window.grecaptcha.getResponse());

          if (!this.fields.recaptcha()) {
            return {
              presence: {
                message: d('Webform/Validation/RecaptchaNeeded')
              }
            };
          }
        }
      }.bind(this),
    };

    this.customValidate = function (field, fieldrules) {
      return customValidators.validate(field, fieldrules);
    };

    this.extendFields(this);

  }));

  // Public functions
  return FormModel;
});