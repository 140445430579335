defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/Components/FAQForm', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Render',
  'Common/Framework/EventHandling/Scripts/OutsideClick',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Data/UserInformation',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/Dictionary',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Models/FormModel',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/FormApi',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Templates/Icon',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Templates/Receipt',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Components/InputField',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Components/Recaptcha',
], function (Component, Render, OutsideClick, LoginCache, UserInformation, Dictionary, FormModel, Utils, FormApi, Icon, Receipt, InputField, Recaptcha) {

  Component('faq-form', [Dictionary], function (m, route, settings) {

    var root = {
      controller: function () {
        this.d = Dictionary.get;
        this.isLoading = m.prop(false);
        this.recaptchaEnabled = m.prop(settings.recaptchaEnabled);
        this.userContext = m.prop('/dli');
        this.brandMenuOpen = m.prop(false);
        this.subjectDropdownOpen = m.prop(false);
        this.originalUserEmail = m.prop(null);
        this.showUpdateEmailOption = m.prop(false);
        this.updateEmailOptionResponse = m.prop(null);
        var content = {};
        content.dictionary = this.d;
        content.allowedMimeTypes = settings.allowedMimeTypes;

        this.model = new FormModel(content);
        this.errors = m.prop([]);

        this.populateUserFields = function (userInfo) {
          sessionStorage.setItem('kundecenter-info', JSON.stringify(userInfo));

          if (userInfo.FirstName) {
            this.model.fields.name(userInfo.FirstName);
          }

          if (userInfo.Email) {
            this.originalUserEmail(userInfo.Email);
            this.model.fields.email(userInfo.Email);
          }

          if (userInfo.Phone) {
            this.model.fields.phone(userInfo.Phone);
          }
        }.bind(this);

        this.fetchUserInfo = new Promise(function (resolve) {
          var user = LoginCache.getUserObj();
          var userInfo = sessionStorage.getItem('kundecenter-info');

          // If available from sessionStorage resolve() immidiately without doing an API call
          if (userInfo) {
            resolve(JSON.parse(userInfo));
          }

          // If visitor has a userObject, they are logged in to DLI. We can fetch data to get the email and phone number as well
          if (user && user.customerInfo) {
            UserInformation.getDliUser().then(function (response) {
              resolve(this.populateUserFields(response));
            }.bind(this), function () {
              resolve(null);
            });
          // Else try to fetch it from DLO
          } else {
            UserInformation.getDloUser().then(function (response) {
              resolve(this.populateUserFields(response));
            }.bind(this), function () {
              resolve(null);
            });
          }
        }.bind(this));

        this.brandMenu = settings.products;
        this.subjects = settings.recordtypes;

        this.getBrandColor = function (value) {
          var match = value.match(/(#|rgb)\(?[a-z0-9,]+\)?;/i); // Match hex color
          return Array.isArray(match) ? match[0] : null;
        };

        this.setBrand = function (brand) {
          this.model.fields.brand(brand);
          this.brandMenuOpen(false);
        };

        this.setSubject = function (subject) {
          this.model.fields.subject(subject);
          this.subjectDropdownOpen(false);
        };

        this.toggleBrandMenu = function (e) {
          e.preventDefault();
          this.brandMenuOpen(!this.brandMenuOpen());
        }.bind(this);

        this.toggleSubjectDropdown = function (e) {
          e.preventDefault();
          this.subjectDropdownOpen(!this.subjectDropdownOpen());
        }.bind(this);

        this.updateUserEmail = function () {
          var email = this.model.fields.email();
          var context = this.userContext();
          UserInformation.updateEmail(email, context).then(function (response) {
            this.showUpdateEmailOption(false);
            this.originalUserEmail(email);
            this.updateEmailOptionResponse(response);
          }.bind(this), function (response) {
            this.showUpdateEmailOption(false);
            this.updateEmailOptionResponse(response);
          }.bind(this));
        }.bind(this);

        this.checkOldEmail = function () {
          if (this.originalUserEmail() && this.model.fields.email.validState() && this.model.fields.email() !== this.originalUserEmail()) {
            this.showUpdateEmailOption(true);
            m.redraw();
          }
        }.bind(this);

        this.showFileOverview = function (e) {
          var files = e.target.files;

          // Map to a new array, because IE11 does not support forEach on NodeList
          files = [].slice.call(files);

          var filesArray = files.map(function (file) {
            var errors = this.model.customValidate({ file: file }, this.model.fieldrules.files);

            if (errors) {
              file.hasError = true;
              file.errors = errors;
            }

            file.img;

            // If the mime type is an image then display that image
            if (file.type.indexOf('image/') === 0) {
              file.img = {
                src: window.URL.createObjectURL(file),
                alt: '',
              };
            }

            return file;
          }.bind(this));

          this.model.fields.files(filesArray);
        }.bind(this);

        this.backLink = function (e) {
          e.preventDefault();
          window.history.back();
        };

        this.removeFile = function (e, index) {
          e.preventDefault();
          this.model.fields.files().splice(index, 1);
        }.bind(this);

        this.submit = function (e) {
          e.preventDefault();

          var stepFields = ['name', 'email', 'message', 'recaptcha'];

          this.model.validate(stepFields).then(function () {
            if (!this.model.getStepState(stepFields).state) {
              var errors = document.querySelectorAll('.has-error');
              Utils.scrollTo(document.documentElement, errors[0].offsetTop - 100, 250);
              return;
            } else if (document.querySelectorAll('.has-error').length) {
              return;
            }

            var data = new FormData();

            data.append('subject', 'Forespørgsel fra web form');
            data.append('suppliedname', this.model.fields.name());
            data.append('suppliedemail', this.model.fields.email());
            data.append('description', this.model.fields.message());

            if (this.model.fields.recaptcha()) {
              data.append('recaptcha', this.model.fields.recaptcha());
            }

            for (var i = 0; i < this.model.fields.files().length; i++) {
              data.append('files[]', this.model.fields.files()[i]);
            }

            if (this.model.fields.brand() && this.model.fields.brand().productwebformid) {
              data.append('product', this.model.fields.brand().productwebformid);
            }

            if (this.model.fields.subject() && this.model.fields.subject().recordid) {
              data.append('recordtypeid', this.model.fields.subject().recordid);
            }

            this.isLoading = m.prop(true);

            FormApi.submitForm(data).then(function () {
              route('/kvittering')();
              this.isLoading = m.prop(false);
              window.scrollTo(0, 0);
            }.bind(this), function (response) {
              if (response && response.errors) {
                this.errors(response.errors);
                window.grecaptcha.reset();
              }
              this.isLoading = m.prop(false);
              m.redraw();
            }.bind(this));
          }.bind(this));
        }.bind(this);

        this.fetchUserInfo.then(function () {
          m.redraw(true);
        });
      },
      view: function (controller) {
        var render = Render();

        render.ready.then(function (nodes) {
          if (nodes['subjectDropdown'] && nodes['subjectDropdown'].rendered) {
            OutsideClick(nodes['subjectDropdown'].$element, nodes['subjectDropdown'].context, function () {
              controller.subjectDropdownOpen(false);
            });
          }
        });

        var sectionTitle = function (text) {
          return m('h2.faq-form__group-title', text);
        };

        if (route() === '/kvittering') {
          return (Receipt({
            button: {
              href: '/',
              text: controller.d('Webform/Receipt/ButtonText')
            },
            icon: 'kc-success',
            title: controller.d('Webform/Receipt/Title'),
            description: controller.d('Webform/Receipt/Description')
          }));
        }

        return m('div', [
          m('.faq-form__header', [
            m('a.faq-backlink__link', {
              href: '#',
              onclick: controller.backLink
            }, [
              Icon('icon-arrow-left', 'faq-backlink__icon'),
              controller.d('Webform/BackLink')
            ]),
            m('h1.faq-form__title', controller.d('Webform/Title')),
            m('h2.faq-form__description', controller.d('Webform/Description'))
          ]),
          m('div.faq-form__group', [
            m('h2.faq-form__group-title', controller.d('Webform/PersonalDetailsTitle')),
            m('div.faq-form__row', [
              m(InputField, {
                type: 'text',
                label: controller.d('Webform/Name/Label'),
                placeholder: controller.d('Webform/Name/Placeholder'),
                isRequired: true,
                hasError: controller.model.fields.name && !controller.model.fields.name.validState(),
                model: controller.model.fields.name,
                onblur: controller.model.fields.name.validate
              }),
              m(InputField, {
                type: 'tel',
                label: controller.d('Webform/Phone/Label'),
                placeholder: controller.d('Webform/Phone/Placeholder'),
                model: controller.model.fields.phone
              }),
            ]),
            m('div', [
              m(InputField, {
                type: 'email',
                label: controller.d('Webform/Email/Label'),
                placeholder: controller.d('Webform/Email/Placeholder'),
                isRequired: true,
                hasError: controller.model.fields.email && !controller.model.fields.email.validState(),
                model: controller.model.fields.email,
                onfocus: function () {
                  controller.updateEmailOptionResponse(false);
                  controller.showUpdateEmailOption(false);
                },
                onblur: function () {
                  controller.model.fields.email.validate().then(function () {
                    // If email is valid then do check for instant email update to PAM
                    controller.checkOldEmail();
                  });
                }
              }),
              (controller.showUpdateEmailOption() ?
                m('small.faq-form__update-option', [
                  controller.userContext() === '/dlo' ?
                    m.trust(controller.d('Webform/Email/UpdateOptionDLO'))
                    :
                    m('', [
                      m.trust(controller.d('Webform/Email/UpdateOption', { NEWEMAIL: controller.model.fields.email() }) + ' '),
                      m('a', {
                        href: '#',
                        onclick: controller.updateUserEmail
                      }, controller.d('Webform/Email/UpdateOptionLink'))
                    ])
                ]) : null),
              (controller.updateEmailOptionResponse() ?
                m('small', {
                  class: 'faq-form__update-option faq-form__update-option--' + controller.updateEmailOptionResponse().status
                }, controller.updateEmailOptionResponse().message)
                : null)
            ]),
          ]),
          m('div.faq-form__group', [
            sectionTitle(controller.d('Webform/FormRequestTitle')),
            m('div.faq-form__row', [
              m('.faq-form__column faq-form__input-group faq-form__brand-container', [
                m('.faq-form__input-wrap', [
                  m('.faq-form__styled-select', [
                    m('select.faq-form__select faq-form__select--mobile', [
                      m('option',
                        { selected: 'selected', disabled: 'disabled' },
                        controller.model.fields.subject() ? controller.model.fields.subject().recordLabel : controller.d('Webform/SubjectButtonText')
                      ),
                      controller.subjects.map(function (subject) {
                        return m('option', {
                          value: subject.recordid,
                          onclick: function () {
                            controller.setSubject(subject);
                          }
                        }, subject.recordlabel);
                      })
                    ]),
                    m('a',
                      {
                        config: render.depend('subjectDropdown'),
                        class: 'faq-form__styled-select-label faq-form__select--desktop',
                        onclick: function (e) {
                          controller.toggleSubjectDropdown(e);
                        }
                      },
                      controller.model.fields.subject() ? controller.model.fields.subject().recordlabel : controller.d('Webform/SubjectButtonText')
                    ),
                    Icon('kc-arrow-down', 'faq-form__styled-select-icon')
                  ])
                ]),
                controller.subjectDropdownOpen() ?
                  m('.faq-form__styled-dropdown avalon-list', [
                    controller.subjects.map(function (subject) {
                      return m('.avalon-list__item', [
                        m('a', {
                          class: 'avalon-list__link js-faq-form__input-select',
                          href: '#',
                          onclick: function (e) {
                            e.preventDefault();
                            controller.setSubject(subject);
                          }
                        }, subject.recordlabel)
                      ]);
                    })
                  ])
                  : null
              ]),
              m('.faq-form__column faq-form__input-group .faq-form__brand-container', [
                m('button', {
                  class: 'faq-form__input faq-form__input-button',
                  config: function (element) {
                    if (controller.model.fields.brand()) {
                      var color = controller.getBrandColor(controller.model.fields.brand().productimagecolor);
                      element.setAttribute('style', 'background-color: ' + color);
                      element.classList.add('faq-form__selected-brand');
                    }
                  },
                  onclick: function (e) {
                    controller.toggleBrandMenu(e);
                  }
                }, [
                  (controller.model.fields.brand() ?
                    m('img',
                      {
                        class: 'faq-form__selected-brand-image',
                        src: controller.model.fields.brand().productimageurl
                      }
                    )
                    :
                    m('span', { class: 'faq-brand-menu__button-text' }, controller.d('Webform/BrandMenu/ButtonText'))
                  ),
                  Icon('kc-arrow-down', 'faq-brand-menu__button-icon faq-form__input-button-icon')
                ]),
                controller.brandMenuOpen() ?
                  m('.faq-brand-menu', [
                    m('.faq-brand-menu__items', [
                      controller.brandMenu.map(function (brand) {
                        return m('a.faq-brand-menu__link', {
                          href: '#',
                          onclick: function (e) {
                            e.preventDefault();
                            controller.setBrand(brand);
                          },
                        }, [
                          m('img', {
                            class: 'faq-brand-menu__image',
                            src: brand.productimageurl,
                            alt: brand.productlabel,
                            style: 'background-color: ' + controller.getBrandColor(brand.productimagecolor)
                          })
                        ]);
                      })
                    ])
                  ])
                  : null,
              ])
            ]),
            m(InputField, {
              type: 'textarea',
              label: controller.d('Webform/Message/Label'),
              placeholder: controller.d('Webform/Message/Placeholder'),
              isRequired: true,
              hasError: controller.model.fields.message && !controller.model.fields.message.validState(),
              model: controller.model.fields.message,
              onblur: controller.model.fields.message.validate
            }),
            m('.faq-form__file-list js-faq-form__file-list', [
              controller.model.fields.files().map(function (file, index) {
                return m('.faq-form__file-item-wrap', [
                  m('.faq-form__file-item ' + (file.hasError ? 'has-error' : 'is-valid'), [
                    file.img ? m('img.faq-form__file-image', file.img) : null,
                    m('span.faq-form__file-text', controller.d('Webform/Files/AttachmentLabel', { FILENAME: file.name, FILESIZE: parseInt(file.size / 1024, 10) })),
                    m('button.faq-form__file-remove', {
                      onclick: function (e) { controller.removeFile(e, index); }
                    }, m.trust('&times;'))
                  ]),
                  (file.hasError ? m('.faq-form__error-message', file.errors.file[0]) : null)
                ]);
              }),
            ]),
            m('.faq-form__input-group', [
              m('label.faq-form__input faq-form__input-button', {
                for: 'file'
              }, [
                Icon('kc-attachment', 'faq-form__input-icon'),
                controller.d('Webform/Files/Label'),
              ]),
              m('input.faq-form__file', {
                type: 'file',
                id: 'file',
                multiple: 'multiple',
                onchange: controller.showFileOverview
              }),
              m('p', [
                m('small', controller.d('Webform/Files/EncryptionNotice'))
              ])
            ]),
          ]),
          controller.recaptchaEnabled() ?
            m(Recaptcha, {
              model: controller.model.fields.recaptcha,
              id: 'recaptchafield',
              class: 'faq-form__recaptcha'
            })
            : null,
          m('.faq-form__group', [
            controller.errors().length ?
              controller.errors().map(function (err) {
                return m('div.faq-form__error-message-container', {
                  class: 'faq-form__error-message faq-form__error-message--center'
                }, m.trust(err.Message));
              })
              : null,
            m('button.faq-form__button', {
              onclick: controller.submit,
              disabled: controller.isLoading(),
            }, controller.d('Webform/SubmitButtonText'))
          ])
        ]);
      }
    };

    route('/', root);
    route('/kvittering', root);
  });
});
