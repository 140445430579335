defineDs('DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/FAQSearchSuggestions', [
  'Common/Framework/EventHandling/Scripts/OutsideClick',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/NonMithril/ComponentLoader',
  'DanskeSpil/Domain/AvalonKundecenter/Scripts/Helpers/Utils'
], function (OutsideClick, ApiRequest, ComponentLoader, Utils) {

  ComponentLoader('faq-search-suggestions', function (settings) {
    var $element = this.$element;

    this.debounce = Utils.debounce;
    this.searchApi = settings.searchApi || null;
    this.container = $element.querySelector(settings.container) || $element; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.activeClass = settings.activeClass || 'has-suggestions';
    this.loadingClass = settings.loadingClass || 'is-loading';
    this.searchInput = $element.querySelector(settings.searchInput || '.js-faq-search-input'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.titleTemplate = $element.querySelector(settings.titleTemplate || '#js-faq-suggestions-titleTemplate'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.listItemTemplate = $element.querySelector(settings.listItemTemplate || '#js-faq-suggestions-listItemTemplate'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.target = $element.querySelector(settings.target || '.js-faq-suggestion-list-placeholder'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    this.brandId = settings.brandId || '';
    this.brandEnsightenId = settings.brandEnsightenId !== '' ? settings.brandEnsightenId : 'ds_glo';
    this.baseUrl = settings.baseUrl || '/kundecenter/artikel/';
    this.searchString = '';
    this.ensightenTrackingSubmitSent = false;

    this.init = function () {
      var faqSearchResult = JSON.parse(localStorage.getItem('faq-search-result'));
      if (faqSearchResult) {
        localStorage.removeItem('faq-search-result');
        window.dataLayer.push({
          event: 'gaEvent',
          eventSyntax: faqSearchResult
        });
      }
    }.bind(this);

    this.createTitle = function (items) {
      return this.titleTemplate.innerHTML.replace('{count}', items.length);
    }.bind(this);

    this.createList = function (items) {
      var html = '';
      items.forEach(function (item) {
        var link = this.baseUrl + this.convertToKebabCase(item.title);
        html += this.listItemTemplate.innerHTML.replace('{link}', link).replace('{title}', item.title).replace('{ensighten}');
      }.bind(this));

      return html;
    }.bind(this);

    this.buildSuggestionsList = function (items) {
      var html = '';

      if (!items.length) {
        return html;
      }

      html += this.createTitle(items);
      html += this.createList(items);

      return html;
    };

    this.convertToKebabCase = function (str) {
      if (!str) return '';

      var output = str.toLowerCase();

      output = output.replace(/(\.|\?|,)/g, ''); // Remove all characters that are not words or whitespaces
      output = output.replace(/\s+/g, '-'); // Convert all whitespace characters to dashes

      return output;
    };

    this.showSuggestionsList = function (show) {
      if (show) {
        this.container.classList.add(this.activeClass);
        this.addTrackingToSuggestions();
      } else {
        this.container.classList.remove(this.activeClass);
      }
    }.bind(this);

    var snakeCaseText = function (text) {
      if (typeof text !== 'string') return text;

      return text.replace(/\s/gi, '_').toLowerCase(); // Replace white space characters with underscore
    };

    /*
      Definition of a "search_action" specced by the Tracking Team:
      A search is performed when:
        The user types in the search field and pause typing for 2 seconds.

      If the user types after 2 seconds the search will now be a "search_action_refinement".
      If the user clicks on a search suggestion the "search_action_click_on_result"

      # IU-13973
    */
    var ensightenTracking = function (e, options) {
      var searchResults = this.container.querySelectorAll('.js-tracking-link').length;

      var trackObj = {
        category: 'faq_internal_search',
        label: snakeCaseText(this.searchString),
        search_refinement: 'no', // eslint-disable-line camelcase -- Not our model
        search_number_of_results: searchResults, // eslint-disable-line camelcase -- Not our model
        search_brand: this.brandEnsightenId, // eslint-disable-line camelcase -- Not our model
        search_suggestion: searchResults ? 'yes' : 'no' // eslint-disable-line camelcase -- Not our model
      };

      // Disable lint for non camelcase properties. This is props defined by Ensighten tracking object
      /* eslint-disable camelcase */
      if (options && options.submit) {
        if (this.ensightenTrackingSubmitSent) {
          trackObj.action = 'search_action_refinement';
          trackObj.search_refinement = 'yes';
        } else {
          trackObj.action = 'search_action';
          this.ensightenTrackingSubmitSent = true;
        }
      } else if (e && e.target && e.target.innerText) {
        trackObj.action = 'search_action_click_on_result';
        trackObj.search_keyword = snakeCaseText(e.target.innerText);
      }
      /* eslint-enable camelcase */

      if (options && options.cacheTracking) {
        localStorage.setItem('faq-search-result', JSON.stringify(trackObj));
      } else {
        window.dataLayer.push({
          event: 'gaEvent',
          eventSyntax: trackObj
        });
      }
    }.bind(this);

    this.addTrackingToSuggestions = function () {
      var trackingLinks = this.container.querySelectorAll('.js-tracking-link');

      // Map to a new array, because IE11 does not support forEach on NodeList
      trackingLinks = [].slice.call(trackingLinks);

      trackingLinks.forEach(function (link) {
        link.addEventListener('click', function () {
          ensightenTracking(link, { cacheTracking: true });
        }, false);
      });
    };

    this.container.querySelector('.js-faq-search__button').addEventListener('click', function (e) {
      ensightenTracking(e, { submit: true });
    });

    this.fetchSuggestions = function (userInput) {
      // If userInput is less than 3 characters do early return as no meaningful suggestions will come up
      if (userInput.length < 3) {
        this.showSuggestionsList(false);
        return;
      }

      ApiRequest({
        url: this.searchApi,
        data: {
          search: userInput,
          brand: this.brandId
        }
      }).then(function (response) {
        try {
          var data = JSON.parse(response.data);

          this.target.innerHTML = this.buildSuggestionsList(data.articles || []);

          if (data.articles.length) {
            this.showSuggestionsList(true);
          } else {
            this.showSuggestionsList(false);
          }
        } catch (e) {
          console.warn('[FAQSearchSuggestions] could not resolve despite a successfull API response. Maybe response data is not valid JSON?', e);
        }

        if (this.ensightenTrackingSubmitSent) {
          ensightenTracking(null, { submit: true });
        }
      }.bind(this));
    }.bind(this);

    var $searchSuggestions = $element.querySelector('.js-faq-search-suggestions'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    OutsideClick($searchSuggestions, window, function () {
      this.showSuggestionsList(false);
    }.bind(this));

    this.debounceUserInput = this.debounce(function (e) {
      var userInput = e.target.value.trim();

      // User clicks enter, then submit the form
      if (e.keyCode === 13) {
        this.container.querySelector('.js-faq-search__button').click();
      }

      // If the search string is the same, then don't fetch new search suggestions
      if (this.searchString === userInput) {
        return;
      }

      // If search string is abnormally long, then don't fetch anymore suggestions
      if (this.searchString.length > 200) {
        return;
      }

      this.searchString = userInput;

      this.fetchSuggestions(this.searchString);
    }.bind(this), 500); // 500ms debounce functionality is added, so we don't spam the backend with incomplete searches

    this.debounceTriggerTrackingSearch = this.debounce(function (e) {
      ensightenTracking(e, { submit: true });
      // Once we've through the submit tracking param here, we want to remove the event listener again
      this.searchInput.removeEventListener('keyup', this.debounceTriggerTrackingSearch, false);
    }.bind(this), 2000);

    this.searchInput.addEventListener('keyup', this.debounceTriggerTrackingSearch, false);
    this.searchInput.addEventListener('keyup', this.debounceUserInput, false);

    this.init();
  });
});
